// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@import '@angular/material/theming';

@import './variables.scss';

@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
/* <h1> .mat-h1*/
$h1: mat.define-typography-level(
    $font-family: $poppins-font-family,
    $font-weight: 700,
    $font-size: 1.8rem,
    $line-height: 1
);
/* <h2> .mat-h2 */
$h2: mat.define-typography-level(
    $font-family: $poppins-font-family,
    $font-weight: 600,
    $font-size: 1rem,
    $line-height: 1
);
/* <h3> .mat-h2 */
$h3: mat.define-typography-level(
    $font-family: $poppins-font-family,
    $font-weight: 500,
    $font-size: 0.8rem,
    $line-height: 1
);
/* <h4> .mat-h3*/
$h4: mat.define-typography-level(
    $font-family: $poppins-font-family,
    $font-weight: 500,
    $font-size: 0.8rem,
    $line-height: 1
);
/* body text .mat-body*/
$body: mat.define-typography-level(
    $font-family: $poppins-font-family,
    $font-weight: 400,
    $font-size: 0.7rem,
    $line-height: 1.2
);
/* bold body text/label .mat-body-2*/
$body-strong: mat.define-typography-level(
    $font-family: $poppins-font-family,
    $font-weight: 600,
    $font-size: 0.7rem,
    $line-height: 1.2
);
/* Smaller body and hint text. .mat-caption*/
$caption: mat.define-typography-level(
    $font-family: $poppins-font-family,
    $font-weight: 600,
    $font-size: 0.75rem
);
/* Buttons and anchors text. No class. Used only in components.*/
$button: mat.define-typography-level(
    $font-family: $poppins-font-family,
    $font-weight: 600,
    $font-size: 0.65rem
);
/* Buttons and anchors text. No class. Used only in components.*/
$input: mat.define-typography-level(
    $font-family: $poppins-font-family,
    $font-weight: 400,
    $font-size: 0.65rem,
    $line-height: 1
);
$vsm-legacy-typography-config: mat.define-legacy-typography-config(
    $headline: $h1,
    $title: $h2,
    $subheading-2: $h3,
    $subheading-1: $h4,
    $body-1: $body,
    $body-2: $body-strong,
    $caption: $caption,
    $button: $button,
    $input: $input
);

$vsm-typography-config: mat.define-typography-config(
    $font-family: $poppins-font-family,
    $headline-5: $h1,
    $headline-6: $h2,
    $subtitle-1: $h3,
    $subtitle-2: $h4,
    $body-1: $body,
    $body-2: $body-strong,
    $caption: $caption,
    $button: $button
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($vsm-typography-config);`
@include mat.all-legacy-component-typographies($vsm-legacy-typography-config);
@include mat.all-component-typographies($vsm-typography-config);
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$primary-palette: (
    50: $primary-50,
    100: $primary-100,
    200: $primary-200,
    300: $primary-300,
    400: $primary-400,
    500: $primary-500,
    600: $primary-600,
    700: $primary-700,
    800: $primary-800,
    900: $primary-900,
    A100: $primary-A100,
    A200: $primary-A200,
    A400: $primary-A400,
    A700: $primary-A700,
    contrast: (
        50:
            rgba(
                $color: black,
                $alpha: 0.87
            ),
        100:
            rgba(
                $color: black,
                $alpha: 0.87
            ),
        200:
            rgba(
                $color: black,
                $alpha: 0.87
            ),
        300: $on-primary-color,
        400: $on-primary-color,
        500: $on-primary-color,
        600: $on-primary-color,
        700: $on-primary-color,
        800: $on-primary-color,
        900: $on-primary-color,
        A100:
            rgba(
                $color: black,
                $alpha: 0.87
            ),
        A200:
            rgba(
                $color: black,
                $alpha: 0.87
            ),
        A400: $on-primary-color,
        A700: $on-primary-color
    )
);

$accent-palette: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
    contrast: (
        50: $primary-50,
        100: $primary-100,
        200: $primary-200,
        300: $primary-300,
        400: $primary-400,
        500: $primary-500,
        600: $primary-600,
        700: $primary-700,
        800: $primary-800,
        900: $primary-900,
        A100: $primary-A100,
        A200: $primary-A200,
        A400: $primary-A400,
        A700: $primary-A700
    )
);
$warn-palette: (
    100: $failure-color,
    500: $failure-color,
    700: $failure-color,
    contrast: (
        100: #ffffff,
        500: #ffffff,
        700: #ffffff
    )
);

$default-palette: (
    50: $default-50,
    100: $default-100,
    200: $default-200,
    300: $default-300,
    400: $default-400,
    500: $default-500,
    600: $default-600,
    700: $default-700,
    800: $default-800,
    900: $default-900,
    A100: $default-A100,
    A200: $default-A200,
    A400: $default-A400,
    A700: $default-A700,
    contrast: (
        50:
            rgba(
                $color: white,
                $alpha: 0.87
            ),
        100:
            rgba(
                $color: white,
                $alpha: 0.87
            ),
        200:
            rgba(
                $color: white,
                $alpha: 0.87
            ),
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100:
            rgba(
                $color: white,
                $alpha: 0.87
            ),
        A200:
            rgba(
                $color: white,
                $alpha: 0.87
            ),
        A400: #ffffff,
        A700: #ffffff
    )
);
$default-accent-palette: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
    contrast: (
        50: $default-50,
        100: $default-100,
        200: $default-200,
        300: $default-300,
        400: $default-400,
        500: $default-500,
        600: $default-600,
        700: $default-700,
        800: $default-800,
        900: $default-900,
        A100: $default-A100,
        A200: $default-A200,
        A400: $default-A400,
        A700: $default-A700
    )
);

$default-primary: mat.define-palette($default-palette);
$default-accent: mat.define-palette($default-accent-palette);
$default-warn: mat.define-palette($warn-palette);
$default-theme: mat.define-light-theme(
    (
        color: (
            primary: $default-primary,
            accent: $default-accent,
            warn: $default-warn
        )
    )
);

$primary: mat.define-palette($primary-palette);
$accent: mat.define-palette($accent-palette);
$warn: mat.define-palette($warn-palette);
$DashboardUI-theme: mat.define-light-theme(
    (
        color: (
            primary: $primary,
            accent: $accent,
            warn: $warn
        ),
        density: 0
    )
);
@include mat.all-component-themes($default-theme);
@include mat.all-legacy-component-themes($DashboardUI-theme);
@include mat.slider-theme($DashboardUI-theme);
@include mat.form-field-theme($DashboardUI-theme);
@include mat.input-theme($DashboardUI-theme);
@include mat.select-theme($DashboardUI-theme);
@include mat.checkbox-theme($DashboardUI-theme);
@include mat.progress-spinner-theme($DashboardUI-theme);
@include mat.button-theme($default-theme);
@include mat.legacy-button-theme($default-theme);
@include mat.legacy-radio-theme($default-theme);
@include mat.legacy-slide-toggle-theme($default-theme);

/* You can add global styles to this file, and also import other style files */

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

html {
    color: $body-color;
    font-size: 14px;
    height: 100vh;
}

body {
    margin: 0;
}

.mat-h1,
.mat-h2,
.mat-h2,
.mat-h3,
h1,
h2,
h3,
h4 {
    color: $header-color;
}
.mat-h1,
.mat-h2,
.mat-h2,
.mat-h3,
.mat-body-1,
.mat-body-2 {
    margin: 0 0 0.25rem !important;
}
//scrollbar
/* width */
::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    // background: $lightest-grey-color;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 1rem;
}

::-webkit-scrollbar-corner {
    background: $grey-color-light;
}

tile-grid {
    ::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
    }
}

//common css classes
.flex {
    display: flex;
}

.grid {
    display: grid;
}

.grid-2-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.grid-3-columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.grid-4-columns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.justify-space-between {
    display: flex;
    justify-content: space-between;
}

.justify-space-around {
    display: flex;
    justify-content: space-around;
}

.justify-space-evenly {
    display: flex;
    justify-content: space-evenly;
}

.justify-end {
    display: flex;
    justify-content: flex-end;
}
.justify-start {
    display: flex;
    justify-content: flex-start;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.grow {
    flex-grow: 1;
}

.align-center {
    display: flex;
    align-items: center;
}

.align-start {
    display: flex;
    align-items: flex-start;
}

.align-end {
    display: flex;
    align-items: flex-end;
}

.align-baseline {
    display: flex;
    align-items: baseline;
}

.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-no-wrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.gap-s {
    gap: 0.2rem; // 3.2px
}
.gap-m {
    gap: 0.4rem; // 6.4px
}
.gap-l {
    gap: 0.8rem; //12.8 px
}
.gap-xl {
    gap: 1.6rem; //25.6 px
}

.row-gap-s {
    row-gap: 0.2rem; // 3.2px
}
.row-gap-m {
    row-gap: 0.4rem; // 6.4px
}
.row-gap-l {
    row-gap: 0.8rem; //12.8 px
}
.row-gap-xl {
    row-gap: 1.6rem; //25.6 px
}

.column-gap-s {
    column-gap: 0.2rem; // 3.2px
}
.column-gap-m {
    column-gap: 0.4rem; // 6.4px
}
.column-gap-l {
    column-gap: 0.8rem; //12.8 px
}
.column-gap-xl {
    column-gap: 1.6rem; //25.6 px
}

.error {
    color: $failure-color;
}

.full-height {
    height: 100%;
}

//angular material styles
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-flat-button {
    .mat-h1,
    .mat-h2,
    .mat-h2,
    .mat-h3,
    .mat-body-1,
    .mat-body-2,
    p {
        margin: 0;
        line-height: 2;
    }
}
.mat-toolbar,
.mat-slide-toggle-content,
.mat-mdc-card {
    font-family: inherit;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
    padding: 0 1rem !important;
}
.mat-expansion-panel-header {
    color: $header-color;
    height: 2.3rem !important;
    padding: 0 0.75rem !important;
}
.mat-expansion-panel-body {
    padding: 0 0.75rem 0.75rem !important;
}
.mat-toolbar-row,
.mat-toolbar-single-row {
    height: 3rem;
}

.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
    --mdc-fab-icon-color: #ffffff;
    --mat-mdc-fab-color: #ffffff;
}

.mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-label-text-color: #ffffff;
}

.mat-mdc-card {
    color: inherit;
    height: 100%;
    padding: 0.5rem !important;
}

.mat-mdc-dialog-container,
.mat-mdc-card {
    border-radius: 0.5rem !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.mat-mdc-option,
.mat-mdc-select,
.mat-mdc-checkbox {
    font-family: inherit;
}

.mat-mdc-dialog-content {
    min-height: 3rem;
}

.default-notification-overlay {
    background-color: $grey-color-darker;
}
.success {
    color: $success-color;
}
.info {
    color: $infoblue-color;
}
.warning {
    color: $warning-color;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: white !important;
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0 0.75rem !important;
    box-sizing: content-box;
}

.mat-mdc-form-field-infix {
    min-height: 2rem !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.mat-mdc-select-arrow-wrapper {
    height: 1rem !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 1.5rem;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(-26.75px)
        scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    transform: var(--mat-mdc-form-field-label-transform);
}

//table filters
mat-header-cell {
    .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix:has(input) {
        width: 100%;
    }

    .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
        margin-top: 0rem;
    }

    .mat-sort-header-content {
        flex-direction: column;
        align-items: flex-start;
    }
    .mat-sort-header-container {
        align-items: baseline;
    }
    .mat-mdc-form-field-infix {
        min-height: 15px !important;
        padding-top: 0rem !important;
        padding-bottom: 0 !important;
        max-width: 100%;
    }
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}
th.right-column {
    .mat-sort-header-content {
        flex-direction: column;
        align-items: flex-end;
    }
}

sup {
    font-size: 0.4rem;
    .mat-icon {
        font-size: 0.4rem;
        width: 0.4rem;
        height: 0.4rem;
    }
}

.mat-icon.medium-icon {
    font-size: 0.8rem;
    width: 0.8rem;
    height: 0.8rem;
}

.clickable:hover {
    cursor: pointer;
}

.dashlet-link-underline:hover {
    cursor: pointer;
    text-decoration: underline;
    transition: text-dectoration ease 200ms;
}
.equipment-detail-labels {
    padding-right: 0.5rem;
    border-right: 1px solid $body-color;
    white-space: nowrap;
}

.warning-triangle {
    font-size: 0.8rem;
    height: 0.8rem !important;
    width: 0.8rem !important;
}
.equipment-detail-values {
    overflow: hidden;
    div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.metric {
    border-left: 4px solid transparent;
    padding: 0 0.5rem;
}

.mdc-floating-label {
    transform: var(
        --mat-mdc-form-field-label-transform,
        translateY(-50%) translateX(calc(1 * (14px + var(--mat-mdc-form-field-label-offset-x, 0px))))
    ) !important;
}

.mdc-checkbox__checkmark {
    color: $default-50 !important;
}
.mat-mdc-slide-toggle {
    margin: 3px;
}

.custom-tooltip {
    .mdc-tooltip__surface {
        white-space: pre-line !important;
        max-width: max-content;
        text-align: left;
    }
}
