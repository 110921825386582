//colour definitions. These variables can be accessed in typescript
:root {
    --success-color: #00CC5F;
    --success-color-dark: #009a31;
    --failure-color: #FF0C47;
    --failure-color-dark: #c40020;
    --warning-color: #FFC20C;
    --warning-color-dark: #ff9100;
    --infopink-color: #ff0ceb;
    --infopink-color-dark: #c700b8;
    --infoblue-color: #3399ff;
    --infoblue-color-light: #9accfe;
    --infoblue-color-lighter: #c0dfff;
    --infoblue-color-dark: #0360bd;
    --infoblue-color-darker: #00378c;
    --infoyellow-color: #F7F700;
    --infopurple-color: #B973EE;
    --infopurple-color-dark: #66177F;
    --infocyan-color: #00fbff;
    --infocyan-color-dark: #008B8B;
    --infobrown-color: #8B4513;
    --infobrown-color-light: #fc9c86;
    
    --grey-color: #757F8F;
    --grey-color-light: #B6BBC3;
    --grey-color-lighter: #e3e5e8;
    --grey-color-dark: #363b42;
    --grey-color-darker: #202327;

} 


//branding colours. These are populated through API
$primary-50: var(--primary-50); 
$primary-100: var(--primary-100); 
$primary-200: var(--primary-200); 
$primary-300: var(--primary-300); 
$primary-400: var(--primary-400); 
$primary-500: var(--primary-500); 
$primary-600: var(--primary-600); 
$primary-700: var(--primary-700); 
$primary-800: var(--primary-800); 
$primary-900: var(--primary-900); 
$primary-A100: var(--primary-A100); 
$primary-A200: var(--primary-A200); 
$primary-A400: var(--primary-A400); 
$primary-A700: var(--primary-A700); 

$on-primary-color: var(--on-primary-color);

$default-50: #e7f3ff; 
$default-100: #c0dfff; 
$default-200: #9accfe; 
$default-300: #73b8fd; 
$default-400: #58a9f9; 
$default-500: #3399ff; 
$default-600: #328fed; 
$default-700: #0f83f6; 
$default-800: #0576e6; 
$default-900: #0360bd; 
$default-A100: #a4d1fe; 
$default-A200: #7abcfd; 
$default-A400: #6bb4fc; 
$default-A700: #62aefb; 


//other scss variables that are imported to other scss files across the application
$body-color: #222222;
$header-color: #444444;

$grey-color: var(--grey-color);
$grey-color-light: var(--grey-color-light);
$grey-color-lighter: var(--grey-color-lighter);
$grey-color-dark: var(--grey-color-dark);
$grey-color-darker: var(--grey-color-darker);

$success-color: var(--success-color);
$success-color-dark: var(--success-color-dark);

$failure-color: #FF0C47;
$failure-color-dark: var(--failure-color-dark);

$warning-color: var(--warning-color);
$warning-color-dark: var(--warning-color-dark);

$infopink-color: var(--infopink-color);
$infopink-color-dark: var(--infopink-color-dark);

$infoblue-color: var(--infoblue-color);
$infoblue-color-light: var(--infoblue-color-light);
$infoblue-color-lighter: var(--infoblue-color-lighter);
$infoblue-color-dark: var(--infoblue-color-dark);
$infoblue-color-darker: var(--infoblue-color-darker);

$poppins-font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
